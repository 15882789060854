<template>
  <div
    v-if="!displayWarning"
    class="main"
  >
    <div
      v-if="loading"
      class="backdrop"
    />
    <div class="u-d-f u-jc-fe">
      <span
        class="link"
        @click="readAll"
      >
        Marquer tout comme lu
      </span>
    </div>
    <div
      class="hr"
      :class="{ 'is-loading': loading }"
    />
    <div
      v-if="loading && !notifications.length"
      class="u-mb-5"
    >
      <div
        v-for="(fake, index) in fakes"
        :key="index"
      >
        <FakeNotification />
        <hr />
      </div>
    </div>
    <div v-else>
      <div
        v-for="notification in notifications"
        :key="notification.id"
      >
        <div
          class="p-msg"
          :class="{ 'is-read': notification.read_at }"
          @click="() => accessToNotification(notification)"
        >
          <p
            class="p-msg__content"
            :class="{
              'p-msg__content--main': isWapNotification(notification),
            }"
          >
            {{ notification.content }}
          </p>
          <time
            :datetime="notification.created_at"
            class="p-msg__time"
          >
            {{ dateToMoment(notification.created_at) }}
          </time>
        </div>
        <hr />
      </div>
    </div>
    <div>
      <div v-if="hasLoadedMore && !more">
        <div class="end">Fin des notifications.</div>
      </div>
      <template v-if="!notifications.length">
        <div v-if="infiniteLoading">
          <div
            v-for="fake in fakes"
            :key="fake"
          >
            <FakeNotification />
            <hr />
          </div>
        </div>
        <div v-else></div>
      </template>
    </div>
  </div>
  <div v-else>
    <p class="u-mb-5">Cette notification n'est plus valide</p>
    <div class="u-d-f u-jc-fe">
      <ButtonSimple
        type="button"
        label="Retour"
        @click="back"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'
import type { CustomNotification, RewardModalProps } from '~/types'

const auth = useAuth()
const vfm = useVfm()

const emit = defineEmits<{
  openRewardModal: [rewardModalProps: RewardModalProps]
}>()

const notificationsStore = useNotificationsStore()

const { notifications, more } = storeToRefs(notificationsStore)

const infiniteLoading = ref(false)
const hasLoadedMore = ref(false)
const infiniteHandler = async () => {
  hasLoadedMore.value = true

  infiniteLoading.value = true
  await notificationsStore.loadMoreNotifications()
  infiniteLoading.value = false
}

onMounted(async () => {
  await nextTick()
  const modal = () => document.querySelector('.modal') as HTMLElement

  useInfiniteScroll(modal, infiniteHandler, {
    canLoadMore: () => more.value,
  })
})

if (notifications.value.length) {
  notificationsStore.loadNewerNotifications()
}
notificationsStore.fetchNotifications()

const fakes = 5
const loading = ref(false)
const displayWarning = ref(false)

const accessToNotification = async (notification: CustomNotification) => {
  loading.value = true

  await notificationsStore.readNotification(notification)

  if (notification.deleted) {
    displayWarning.value = true
    loading.value = false
    return
  }

  const commonProps = {
    badges: notification.badges_reward,
    points: notification.points_total,
    ranking: notification.ranking,
  }

  switch (notification.type.slug) {
    // Badge unlocked
    case 'user-new-badge': {
      return emit('openRewardModal', {
        title: `Bravo ${auth.data.value?.firstname}`,
        messages: ['Vous avez gagné un nouveau badge'],
        ...commonProps,
        actions: [
          {
            type: 'route',
            route: '/mon-profil',
            label: 'Voir mon profil',
            outline: true,
          },
          {
            type: 'route',
            route: '/avantages',
            label: 'Découvrir mes avantages',
          },
        ],
      })
    }
    // Challenge validated
    case 'user-challenge-validated': {
      return emit('openRewardModal', {
        title: 'Challenge validé !',
        messages: ['Les équipes ont validé votre challenge.'],
        ...commonProps,
        actions: [
          {
            type: 'route',
            route: '/challenges',
            label: 'Voir les autres challenges',
          },
        ],
        tracking: {
          command: 'event',
          eventName: 'popupPage',
          eventParams: {
            popupName: '/popup/challenge/challenge-valide',
          },
        },
      })
    }
    // Challenge refused
    case 'user-challenge-refused': {
      return emit('openRewardModal', {
        title: 'Challenge refusé',
        messages: [
          'Désolé, les équipes ont refusé votre challenge pour la raison suivante&nbsp;:',
          `<strong>${notification.content_refused}</strong>`,
          "N'hésitez pas à nous contacter pour plus de détails.",
        ],
        ranking: notification.ranking,
        actions: [
          {
            type: 'route',
            route: '/challenges',
            label: 'Voir les autres challenges',
          },
        ],
      })
    }
    // New post / like / comment
    case 'user-new-post':
    case 'user-like-post':
    case 'user-comment-post':
    case 'user-comment-nickname':
    case 'user-comment-answer': {
      if (notification.type.content === 'post' && notification.post) {
        const { post } = notification
        const subCategorySlug = post.sub_category?.slug
          ? `${post.sub_category.slug}/`
          : ''

        const route = `/communautes/${post.community?.slug}/${post.category.slug}/${subCategorySlug}${post.slug}`

        vfm.closeAll()
        return navigateTo({ path: route })
      }

      if (
        notification.type.content === 'post_review' &&
        notification.post_review
      ) {
        const { community, category, slug } = notification.post_review

        const route = `/communautes/${community?.slug}/${category?.slug}/detail/${slug}`

        vfm.closeAll()
        return navigateTo({ path: route })
      }

      if (notification.type.content === 'article' && notification.article) {
        const { slug } = notification.article

        vfm.closeAll()
        return navigateTo({ path: `/actualites-playstation/${slug}` })
      }

      if (notification.type.content === 'challenge' && notification.challenge) {
        vfm.closeAll()
        return navigateTo({ path: `/challenge/${notification.challenge.slug}` })
      }

      if (notification.type.content === 'event' && notification.event) {
        vfm.closeAll()
        return navigateTo({ path: `/evenement/${notification.event.slug}` })
      }

      if (notification.type.content === 'contest' && notification.contest) {
        vfm.closeAll()
        return navigateTo({
          path: `/${ROUTES.CONTEST_SIMPLE}/${notification.contest.slug}`,
        })
      }

      if (
        notification.type.content === 'wall_contest' &&
        notification.wall_contest
      ) {
        vfm.closeAll()
        return navigateTo({
          path: `/${ROUTES.CONTEST_WALL}/${notification.wall_contest.slug}`,
        })
      }

      if (
        notification.type.content === 'speed_contest' &&
        notification.speed_contest
      ) {
        vfm.closeAll()
        return navigateTo({
          path: `/${ROUTES.CONTEST_RECORD}/${notification.speed_contest.slug}`,
        })
      }

      if (
        notification.type.content === 'tournament' &&
        notification.tournament
      ) {
        vfm.closeAll()
        return navigateTo({ path: `/tournoi/${notification.tournament.slug}` })
      }

      break
    }
    // New follower
    case 'user-follow': {
      if (!notification.user) return

      vfm.closeAll()
      return navigateTo({ path: `/wapers/${notification.user.slug}` })
    }
    // WAP Team new post published
    case 'wap-new-post': {
      if (!notification.post) return

      vfm.closeAll()
      return navigateTo({
        path: `/actualites-playstation/${notification.post.slug}`,
      })
    }
    // User contest participation
    case 'user-point-participation': {
      const { post, contest_type } = notification

      let path = ''

      switch (contest_type) {
        case 'contest': {
          path = `/${ROUTES.CONTEST_SIMPLE}/${post?.slug}`
          break
        }
        case 'wall_contest': {
          path = `/${ROUTES.CONTEST_WALL}/${post?.slug}`
          break
        }
        case 'speed_contest': {
          path = `/${ROUTES.CONTEST_RECORD}/${post?.slug}`
          break
        }
      }

      vfm.closeAll()
      return navigateTo({ path })
    }
    // Record contest invitation
    case 'speed-contest-invitation':
    case 'speed-contest-invitation-answer': {
      if (!notification.post) return

      vfm.closeAll()
      return navigateTo({
        path: `/${ROUTES.CONTEST_RECORD}/${notification.post.slug}`,
        hash: '#partner',
      })
    }
  }

  loading.value = false
}

const isWapNotification = (notification: CustomNotification) => {
  return notification.type.slug === 'wap-new-post'
}

const readAll = async () => {
  loading.value = true
  await notificationsStore.readAllNotifications()
  loading.value = false
}

const back = () => {
  displayWarning.value = false
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  margin-top: -2rem;
}

hr {
  margin: 2rem 0;
  border-bottom: 0;
  opacity: 0.15;
}

.hr {
  position: relative;
  height: 1px;
  margin: 1rem 0 2rem;
  overflow: hidden;
  background-color: rgb(0 0 0 / 0.15);

  &.is-loading {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -25%;
      display: block;
      width: 25%;
      height: 100%;
      background-color: $blue;
      animation: loading 0.7s infinite linear;
    }
  }
}

.backdrop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

@keyframes loading {
  0% {
    left: -25%;
  }

  90%,
  100% {
    left: 100%;
  }
}

.link {
  font-size: 1.4rem;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $dark;
    transition: background 0.35s ease-in-out;
  }

  &:hover::after {
    background-color: transparent;
  }
}

.p-msg {
  line-height: 1.3;
  cursor: pointer;

  &.is-read {
    opacity: 0.5;
  }

  &__content {
    font-size: 1.2rem;

    &--main:not(.is-read) {
      font-weight: 700;
    }
  }

  &__time {
    color: #999;
    font-size: 1rem;
  }
}

.infinite-loading-container {
  .end {
    text-align: left;
  }
}
</style>
