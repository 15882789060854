import NotificationsModal from '@/components/modal/NotificationsModal.vue'
import RewardModal from '@/components/modal/RewardModal.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'
import type { RewardModalProps } from '~/types'

const onOpenRewardModal = (rewardProps: RewardModalProps) => {
  notificationsModal.patchOptions({
    attrs: {
      modalId: 'reward',
      title: rewardProps.title,
    },
    slots: {
      default: useModalSlot({
        component: RewardModal,
        attrs: {
          data: rewardProps,
        },
      }),
    },
  })
}

const resetNotificationsModal = () => {
  notificationsModal.patchOptions(getNotificationsModalConfig())
}

const getNotificationsModalConfig = () => ({
  attrs: {
    modalId: 'notifications',
    title: 'Vos notifications',
    clickToClose: false,
    onClosed: resetNotificationsModal,
  },
  slots: {
    default: useModalSlot({
      component: NotificationsModal,
      attrs: {
        onOpenRewardModal,
      },
    }),
  },
})

export const notificationsModal = useCustomModal(getNotificationsModalConfig())
