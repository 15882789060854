<template>
  <div class="animated-bg">
    <div class="bg-masker" />
    <div class="bg-masker" />
    <div class="bg-masker" />
  </div>
</template>

<style lang="scss" scoped>
.animated-bg {
  position: relative;
  height: 30px;
  background-color: #ddd;
  background-image: linear-gradient(to right, #eee 10%, #ddd 20%, #eee 33%);
  background-size: 200%;
  animation: placeholder 1.25s infinite linear;
  animation-fill-mode: forwards;
}

@keyframes placeholder {
  0% {
    background-position: 300% 50%;
  }

  100% {
    background-position: -100% 50%;
  }
}

.bg-masker {
  position: absolute;
  background-color: #fff;

  &:nth-child(1) {
    top: 0;
    right: 0;
    width: 15%;
    height: 14px;
  }

  &:nth-child(2) {
    top: 14px;
    left: 0;
    width: 100%;
    height: 6px;
  }

  &:nth-child(3) {
    top: 20px;
    right: 0;
    width: 38%;
    height: 10px;
  }
}
</style>
